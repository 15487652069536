/* eslint-disable indent */
import React, { createContext, useState, useReducer } from 'react'

const initialState = {
  menuOpen: false,
  subnavOpen: false,
  searchQuery: '',
  searchFocussed: false,
  searchResults: [],
  darkTheme: false
}

const LayoutContext = createContext(initialState)

const LayoutProvider = ({ darkTheme, children }) => {
  const [state, dispatch] = useReducer((s, { type, payload }) => {
    switch (type) {
      case 'toggleMenuOpen':
        return { ...s, menuOpen: !s.menuOpen }
      case 'closeMenu':
        return {
          ...s,
          menuOpen: false,
          searchFocussed: false
        }
      case 'openSearch':
        return {
          ...s,
          searchFocussed: true,
          menuOpen: true
        }
      case 'searchBlur':
        return { ...s, searchFocussed: false }
      case 'updateSearch':
        return {
          ...s,
          searchQuery: payload.query,
          searchResults: payload.results
        }
      case 'toggleSubnavOpen':
        return {
          ...s,
          subnavOpen: !s.subnavOpen
        }
      default:
        throw new Error()
    }
  }, {
    ...initialState,
    darkTheme
  })

  return (
    <LayoutContext.Provider value={{ state, dispatch }}>
      {children}
    </LayoutContext.Provider>
  )
}

export { LayoutContext, LayoutProvider }
