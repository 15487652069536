/* eslint-disable */
import React from 'react'
import { Script } from 'gatsby'
import { LayoutProvider } from './src/context/layout'

const GOOGLE_MAPS_API_KEY = process.env.GATSBY_GOOGLE_CLOUD_API_KEY;

export const wrapRootElement = ({ element }) => (
  <>
    {/* <script
      key="googlemaps-api"
      src={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=geometry,drawing`} 
      async
      defer
    /> */}
    <LayoutProvider>{element}</LayoutProvider>
  </>
)

export const shouldUpdateScroll = ({
  routerProps: { location }
}) => {
  const { pathname } = location
  return !pathname.includes('/team/')
}

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer')
  }

  if (typeof ResizeObserver === 'undefined') {
    window.ResizeObserver = (await import('resize-observer-polyfill')).default
  }
}

export const onRouteUpdate = ({ location }) => {
  if (window.gtag) {
    setTimeout(() => {
      window.gtag('event', 'page_view', {
        page_location: location.href,
        page_title: document.title,
      });
    }, 500);
  }
};
